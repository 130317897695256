import API from './api';
import {handle401} from "./interceptorsHandlers";

const api_url = '/api/v1';
const login_url = '/users/sign_in';
const logout_url = '/users/sign_out';
const change_password_url = '/users'
const reset_password_url = '/users/password'

export const buildUrlFor = model => `${API.defaults.baseURL}${api_url}/${model}/`;

export const Urls = {
    ApiCustomer: API.defaults.baseURL + api_url + "/customers/",
    ApiDistribution: API.defaults.baseURL + api_url + "/distributions/",
    ApiProductBatch: API.defaults.baseURL + api_url + "/product_batches/",
    ApiProduction: API.defaults.baseURL + api_url + "/productions/",
    ApiOpenProduction: API.defaults.baseURL + api_url + "/productions_open_production/",
    ApiProductionsControlRecords: API.defaults.baseURL + api_url + "/productions_control_records/",
    ApiTicketExportProductions: API.defaults.baseURL + api_url + "/ticket_export_productions/",
    ApiTicketExportDistributions: API.defaults.baseURL + api_url + "/ticket_export_distributions/",
    ApiProductionConfiguration: API.defaults.baseURL + api_url + "/production_configurations/",
    ApiProductionConfigurationTypes: API.defaults.baseURL + api_url + "/production_types/",
    ApiProduct: API.defaults.baseURL + api_url + "/products/",
    ApiLocation: API.defaults.baseURL + api_url + "/locations/",
    ApiRawMaterial: API.defaults.baseURL + api_url + "/raw_materials/",
    ApiRawMaterialBatch: API.defaults.baseURL + api_url + "/raw_material_batches/",
    ApiSupply: API.defaults.baseURL + api_url + "/supplies/",
    ApiSupplyBatch: API.defaults.baseURL + api_url + "/supply_batches/",
    ApiReception: API.defaults.baseURL + api_url + "/receptions/",
    ApiSupplier: API.defaults.baseURL + api_url + "/suppliers/",
    ApiEmployee: API.defaults.baseURL + api_url + "/employees/",
    ApiNotification: API.defaults.baseURL + api_url + "/notifications/",
    ApiStockStat: API.defaults.baseURL + api_url + "/stock_stat/",
    ApiProductionStat: API.defaults.baseURL + api_url + "/production_stats/",
    ApiProductionDailyStat: API.defaults.baseURL + api_url + "/production_daily_stats/",
    ApiLogIn: API.defaults.baseURL + login_url,
    ApiLogOut: API.defaults.baseURL + logout_url,
    ApiUpdateIntroJsFlags: API.defaults.baseURL + api_url + "/users/update_intro_js_flags",
    ApiUpdateFirstStepsFlags: API.defaults.baseURL + api_url + "/organizations/update_first_steps_flags",
    ApiChangePassword: API.defaults.baseURL + change_password_url,
    ApiResetPassword: API.defaults.baseURL + reset_password_url,
    ApiContactUs: API.defaults.baseURL + api_url + "/contact_us/send_message/",
    ApiSearch: API.defaults.baseURL + api_url + "/search",
    ApiReprocess: API.defaults.baseURL + api_url + "/reprocesses/",
    ApiFormConfiguration: API.defaults.baseURL + api_url + "/form_configurations/",
    ApiCustomFormConfiguration: API.defaults.baseURL + api_url + "/custom_form_configurations/",
    ApiControlRecordsFormConfiguration: API.defaults.baseURL + api_url + "/control_records_form_configurations/",
    ApiControlRecordsForm: API.defaults.baseURL + api_url + "/control_records_forms/",
    ApiCustomForm: API.defaults.baseURL + api_url + "/custom_forms/",
    ApiProductStockDecrease: API.defaults.baseURL + api_url + "/product_stock_decreases/",
    ApiReprocessStockDecrease: API.defaults.baseURL + api_url + "/reprocess_stock_decreases/",
    ApiRawMaterialStockDecrease: API.defaults.baseURL + api_url + "/raw_material_stock_decreases/",
    ApiSupplyStockDecrease: API.defaults.baseURL + api_url + "/supply_stock_decreases/",
    ApiNotificationMessage: API.defaults.baseURL + api_url + "/users/notification/",
    ApiAutoSignUp: API.defaults.baseURL + api_url + "/auto_sign_up/",
    ApiSubscribe: API.defaults.baseURL + api_url + "/subscriptions",
    ApiOrganization: API.defaults.baseURL + api_url + "/organizations/",
    ApiHealthCheck: API.defaults.baseURL + api_url + "/health_check",
    ApiImplementation: API.defaults.baseURL + api_url + "/implementation",
    ApiNonconformity: API.defaults.baseURL + api_url + "/nonconformities/",
    ApiNonconformityMesssage: API.defaults.baseURL + api_url + "/nonconformity_messages/",
    ApiAlert: API.defaults.baseURL + api_url + "/alerts/"
}

API.interceptors.response.use(
  function (response) {
    // Any 2xx status code
    return response;
  },
  function (error) {
    if(error.response.status === 401){
      handle401(error);
    }

    return Promise.reject(error);
  }
);
