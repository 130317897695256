import React from "react";

import {NeedsLogin, NoLogin,NeedsProductionPermissions, NeedsSubscription, NeedsPermission, NeedsAdminPermission} from "./auth";

import {
  Sliders,
  Users,
  Mail,
  Settings,
  Clipboard,
  UploadCloud,
  Video
} from "react-feather";
import asyncComponent from "../components/Async";
import {signInPath, autoSignUpPath} from "./paths";
import {LocalStorage} from "../utils/storage";
import {Permissions, hasPermission, permissionTypes, hasAnyPermission} from "../components/permissions/permissions";
import async from "../components/Async";
import { planFeatures } from "../components/planFeatures/planFeatures";

// Auth components
export const SignIn = NoLogin(() => import("../pages/auth/SignIn"));
export const AutoSignUp = NoLogin(() => import("../pages/auth/AutoSignUp"));
const SignUp = NoLogin(() => import("../pages/auth/SignUp"));
const ResetPassword = NoLogin(() => import("../pages/auth/ResetPassword"));
const NewPassword = NoLogin(() => import("../pages/auth/NewPassword"));
const Page404 = NoLogin(() => import("../pages/auth/Page404"));
const Page500 = NoLogin(() => import("../pages/auth/Page500"));
const ChangePassword = NeedsLogin(() => import("../pages/auth/ChangePassword"));

// Dashboards components
const Default = NeedsPermission(() => import("../pages/dashboards/Default"), Permissions['TRACEABILITY']);

// Home components
const Landing = NeedsLogin(() => import("../pages/landing/Landing"));

// Pages components
const Blank = NeedsSubscription(() => import("../pages/pages/Blank"));

export const AsyncAddRawMaterial = asyncComponent(() => import("../pages/AddElement/rawMaterial"));
const AddRawMaterial = NeedsSubscription(() => import("../pages/AddElement/rawMaterial"));

export const AsyncAddEmployee = asyncComponent(() => import("../pages/AddElement/employee"));
export const AsyncAddSupplier = asyncComponent(() => import("../pages/AddElement/supplier"));
export const AsyncAddCustomer = asyncComponent(() => import("../pages/AddElement/customer"));
export const AsyncAddAlert = asyncComponent(() => import("../pages/AddElement/alert"));
export const AsyncAddProduct = asyncComponent(() => import("../pages/AddElement/product"));
export const AsyncAddSupply = asyncComponent(() => import("../pages/AddElement/supply"));
export const AsyncAddReception = asyncComponent(() => import("../pages/AddElement/reception"));
export const AsyncAddSupplyReception = asyncComponent(() => import("../pages/AddElement/supplyReception"));
export const AsyncAddDistribution = asyncComponent(() => import("../pages/AddElement/distribution"));
export const AsyncAddReprocess = asyncComponent(()=> import("../pages/AddElement/reprocess"));
export const AsyncAddProductionConfiguration = asyncComponent(() => import("../pages/productionConfigurations/ProductionConfiguration"));
export const AsyncAddProductStockDecrease = asyncComponent(() => import("../pages/AddElement/productStockDecrease"));
export const AsyncAddReprocessStockDecrease = asyncComponent(() => import("../pages/AddElement/reprocessStockDecrease"));
export const AsyncAddRawMaterialStockDecrease = asyncComponent(()=> import("../pages/AddElement/rawMaterialStockDecrease"));
export const AsyncAddSupplyStockDecrease = asyncComponent(()=> import("../pages/AddElement/supplyStockDecrease"));
export const AsyncAddProductBatch = asyncComponent(() => import("../pages/AddElement/productBatch"));
export const AsyncAddNonConformity = asyncComponent(() => import("../pages/AddElement/nonconformity"));
export const AsyncAddLocation = asyncComponent(() => import("../pages/AddElement/location"));
export const AsyncAddSupplyLocationEditable = asyncComponent(() => import("../pages/AddElement/supplyLocationEditable"));
export const AsyncAddProductLocationEditable = asyncComponent(() => import("../pages/AddElement/productLocationEditable"));
export const AsyncAddRawMaterialLocationEditable = asyncComponent(() => import("../pages/AddElement/rawMaterialLocationEditable"));

const Employees = NeedsPermission(() => import("../pages/employees/index"), Permissions['ORGANIZATION']);
const Organization = NeedsPermission(() => import("../pages/organizations/index"), Permissions['ORGANIZATION']);
const Customers = NeedsPermission(() => import("../pages/customers/index"), Permissions['CUSTOMERS']);
const RawMaterials = NeedsPermission(() => import("../pages/rawMaterials/index"), Permissions['RAW_MATERIAL_SETTINGS']);
const Formulas = NeedsPermission(() => import("../pages/formulas/index"), Permissions['FORMULAS']);
const Products = NeedsPermission(() => import("../pages/products/index"), Permissions['PRODUCT_SETTINGS']);
const Supplies = NeedsPermission(() => import("../pages/supplies/index"), Permissions['SUPPLY_SETTINGS']);
const Suppliers = NeedsPermission(() => import("../pages/suppliers/index"), Permissions['SUPPLIERS']);
const Receptions = NeedsPermission(() => import("../pages/receptions/index"), Permissions['RAW_MATERIAL_RECEPTION']);
const SupplyReceptions = NeedsPermission(() => import("../pages/supplyReceptions/index"), Permissions['SUPPLY_RECEPTION']);
const SupplyStockDecreases = NeedsPermission(() => import("../pages/supplyStockDecreases/index"), Permissions['SUPPLY_STOCK_DECREASE']);
const Distributions = NeedsPermission(() => import("../pages/distributions/index"), Permissions['PRODUCT_DISTRIBUTION']);
const RawMaterialStockDecreases = NeedsPermission(() => import("../pages/rawMaterialStockDecreases/index"), Permissions['RAW_MATERIAL_STOCK_DECREASE']);
const ControlRecordsProductions = NeedsPermission(() => import("../pages/ControlRecordsProductions/index"), Permissions['APPCC']);
const ProductBatches = NeedsPermission(() => import("../pages/productBatches/index"), Permissions['PRODUCT_STOCK'])
const RawMaterialBatches = NeedsPermission(() => import("../pages/rawMaterialBatches/index"), Permissions['RAW_MATERIAL_STOCK'])
const SupplyBatches = NeedsPermission(() => import("../pages/supplyBatches/index"), Permissions['SUPPLY_STOCK'])
const ReprocessesBatches = NeedsPermission(()=> import("../pages/reprocessesBatches/index"), Permissions['REPROCESS_STOCK'])
const ReprocessesStockDecreases = NeedsPermission(() => import("../pages/reprocessesStockDecreases/index"), Permissions['REPROCESS_STOCK']);
const GeneralSettings = NeedsPermission(() => import("../pages/settings/index"), Permissions['GENERAL_SETTINGS']);
const Alerts = NeedsPermission(() => import("../pages/alerts/index"), Permissions['ALERTS']); 
const KPIConfiguration = NeedsPermission(() => import("../pages/KPIConfiguration/index"), Permissions['KPI']); 
const ControlRecordsConfiguration = NeedsPermission(() => import("../pages/ControlRecordsConfiguration/index"), Permissions['APPCC']);
const ControlRecords = NeedsPermission(() => import("../pages/ControlRecords/index"), Permissions['APPCC']);
const CustomForms = NeedsPermission(() => import("../pages/customFormsConfiguration/index"), Permissions['CUSTOM_FORM_SETTINGS']);
const CustomForm = NeedsPermission(()=> import("../pages/customForm/index"), Permissions['CUSTOM_FORMS']);
const ProductStockDecreases  = NeedsPermission(() => import("../pages/productStockDecreases/index"), Permissions['PRODUCT_STOCK_DECREASE']); 
const ProductStockAdditions  = NeedsPermission(() => import("../pages/productStockAdditions/index"), Permissions['PRODUCT_STOCK_DECREASE']);


//Register components

const Reception = NeedsPermission(() => import("../pages/RegisterForm/RegisterReception"), Permissions['RAW_MATERIAL_RECEPTION'], permissionTypes['WRITE']);
const Supply = NeedsPermission(() => import("../pages/RegisterForm/RegisterSupply"), Permissions['SUPPLY_RECEPTION'], permissionTypes['WRITE']);
const Distribution = NeedsPermission(() => import("../pages/RegisterForm/RegisterDistribution"), Permissions['PRODUCT_DISTRIBUTION'], permissionTypes['WRITE']);
const Procedure = NeedsProductionPermissions(() => import("../pages/productions/ProductionContainer"));
const ConfigProductions = NeedsPermission(() => import("../pages/productionConfigurations/index"), Permissions['PRODUCTION_SETTINGS']);
const Productions = NeedsProductionPermissions(() => import("../pages/productions/index"));
const Costs = NeedsProductionPermissions(() => import("../pages/costs/index"));
const FinalProductionStep = NeedsProductionPermissions(() => import("../pages/productions/FinalProductionStep"));
const RegisterProduction = NeedsPermission(() => import("../pages/productions/RegisterProduction"), Permissions['PRODUCTIONS']);
const ContactUs = NeedsLogin(() => import ("../pages/Contact/index"));
const MyCloud = NeedsPermission(() => import ("../pages/myCloud/index"), Permissions['CLOUD_STORAGE']);
const Nonconformities = NeedsLogin(() => import("../pages/nonconformities/index"));
const AllNonconformities = NeedsAdminPermission(() => import("../pages/nonconformities/index"));
const KPIStock = NeedsPermission(() => import("../pages/KPIStock/index"), Permissions['KPI']);
const KPIProductions = NeedsPermission(() => import("../pages/KPIProductions/index"), Permissions['KPI']);

const Subscription = NeedsLogin(() => import("../pages/Subscription/index"))

const HealthCheck = async(() => import("../pages/healthCheck/index"))

const Implementation = async(() => import ("../pages/Implementation"))

const Notifications = async(() => import ("../pages/Notifications/index"))

export const authRoutes = {
  id: "Mi Cuenta",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      id: "auto-sign-up",
      path: autoSignUpPath,
      name: "Crear cuenta",
      component: AutoSignUp,
      hidden: true
    },
    {
      id: "sign-in",
      path: signInPath,
      name: "Iniciar Sesión",
      component: SignIn,
      hidden: true
    },
    {
      path: "/auth/password/reset",
      name: "Olvidé mi Contraseña",
      component: ResetPassword,
      hidden: true
    },
    {
      path: "/auth/password/new/:token/:email",
      name: "Nueva Contraseña",
      component: NewPassword,
      hidden: true
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
      hidden: true
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
      hidden: true
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
      hidden: true
    },
    {
      path: "/health-check",
      name: "Health Check",
      component: HealthCheck,
      hidden: true
    }
  ]
};

const userRoutes = {
  id: "Mi Cuenta",
  path: "/user",
  icon: <Users />,
  hidden: true,
  children: [
    {
      id: "change-password",
      path: "/user/change-password",
      name: "Cambiar Contraseña",
      component: ChangePassword,
      hidden: true
    },
    {
      path: "/organizations",
      name: "Mi Empresa",
      component: Organization,
      hidden: true
    },
    {
      path: "/employees",
      name: "Mis Empleados",
      component: Employees,
      hidden: true
    },
    {
      path: "/subscription_approved",
      name: "Suscribirse",
      component: Subscription,
      hidden: true
    }
  ]
};

const hideControlRecords = ()=>{
  return !hasPermission(Permissions['APPCC'])
}

const hideGeneralRoutes = ()=>{
  return !hasAnyPermission([Permissions['SUPPLIERS'],Permissions['CUSTOMERS'],Permissions['GENERAL_SETTINGS'], Permissions['CUSTOM_FORM_SETTINGS'], Permissions['ALERTS']])
}

const hideProductionRoutes = ()=>{
  return !hasAnyPermission([Permissions['RAW_MATERIAL_SETTINGS'],Permissions['SUPPLY_SETTINGS'],Permissions['FORMULAS'],Permissions['PRODUCTION_SETTINGS'], Permissions['PRODUCT_SETTINGS']])
}

const hideRawMaterials = ()=>{
  return !hasAnyPermission([Permissions['RAW_MATERIAL_RECEPTION'],Permissions['RAW_MATERIAL_STOCK_DECREASE']])
}

const hideSupplies = ()=>{
  return !hasAnyPermission([Permissions['SUPPLY_RECEPTION'],Permissions['SUPPLY_STOCK_DECREASE']])
}

const hideProduction = ()=>{
  return !hasAnyPermission([Permissions['PRODUCTIONS'],Permissions['STARTED_PRODUCTIONS'],Permissions['FINISHED_PRODUCTIONS'], Permissions['IDENTIFICATION_PRODUCTIONS']])
}

const hideProducts = ()=>{
  return !hasAnyPermission([Permissions['PRODUCT_DISTRIBUTION'],Permissions['PRODUCT_STOCK_DECREASE']])
}

const hideKPI = ()=>{
  return !hasPermission(Permissions['KPI'])
}

const hideStock = ()=>{
  return !hasAnyPermission([Permissions['RAW_MATERIAL_STOCK'],Permissions['SUPPLY_STOCK'],Permissions['PRODUCT_STOCK'],Permissions['REPROCESS_STOCK']])
}

const hideNonconformities = ()=>{
  return !hasAnyPermission([Permissions['NONCONFORMITY']])
}

export const hideConfigurationRoutes = hideGeneralRoutes() && hideProductionRoutes()

export const hideRegisterRoutes = hideStock() && hideProducts() && hideProducts() && hideProduction() && hideSupplies() && hideRawMaterials()

export const hideAnalysisRoutes = !hasPermission(Permissions['TRACEABILITY'])

export const generalRoutes = {
  id: "General",
  header: 'Configuración',
  displayHeader: true,
  path: "/configs",
  icon: <Settings />,
  component: Blank,
  hidden: hideGeneralRoutes(),
  children: [
    {
      path: "/alerts",
      name: "Alertas",
      component: Alerts,
      hidden: !hasPermission(Permissions['ALERTS'])
    },
    {
      path: "/customers",
      name: "Clientes",
      component: Customers,
      hidden: !hasPermission(Permissions['CUSTOMERS'])
    },
    {
      path: "/general_settings",
      name: "Formularios",
      component: GeneralSettings,
      hidden: !hasPermission(Permissions['GENERAL_SETTINGS'])
    },
    /*{
      path: "/KPIConfiguration",
      name: "KPIs",
      component: KPIConfiguration,
      hidden: !hasPermission(Permissions['KPI'])
    },*/
    {
      path: "/custom_forms",
      name: "Planillas Personalizadas",
      component: CustomForms,
      hidden: !hasPermission(Permissions['CUSTOM_FORM_SETTINGS'])
    },
    {
      path: "/suppliers",
      name: "Proveedores",
      component: Suppliers,
      hidden: !hasPermission(Permissions['SUPPLIERS'])
    },
    {
      path: "/control_records/configuration",
      name: "Registros de Control",
      component: ControlRecordsConfiguration,
      hidden: !hasPermission(Permissions['APPCC'], permissionTypes['WRITE'])
    },
  ]
}

export const productionRoutes = {
  id: "Mi Fábrica",
  path: "/configs",
  icon: <Settings />,
  header: 'Configuración',
  displayHeader: hideGeneralRoutes(),
  hidden: hideProductionRoutes(),
  component: Blank,
  children: [
    {
      path: "/formulas",
      name: "Fórmulas",
      component: Formulas,
      hidden: !hasPermission(Permissions['FORMULAS'])
    },
    {
      path: "/supplies",
      name: "Insumos",
      component: Supplies,
      hidden: !hasPermission(Permissions['SUPPLY_SETTINGS'])
    },
    {
      path: "/raw_materials",
      name: "Materias Primas",
      component: RawMaterials,
      hidden: !hasPermission(Permissions['RAW_MATERIAL_SETTINGS'])
    },
    {
      path: "/production_configuration",
      name: "Producción",
      component: ConfigProductions,
      hidden: !hasPermission(Permissions['PRODUCTION_SETTINGS'])
    },
    {
      path: "/products",
      name: "Producto Final",
      component: Products,
      hidden: !hasPermission(Permissions['PRODUCT_SETTINGS'])
    },
  ]
};

const registerRoutes = {
  id: "Materia Prima",
  header: 'Registros',
  path: "/register",
  displayHeader: true,
  hidden: hideRawMaterials(),
  icon: <Clipboard />,
  children: [
    {
      path: "/register/reception",
      name: "Ingreso",
      component: Reception, 
      hidden: !hasPermission(Permissions['RAW_MATERIAL_RECEPTION'], permissionTypes['WRITE'])
    },
    {
      path: "/receptions",
      name: "Histórico",
      component: Receptions,
      hidden:!hasPermission(Permissions['RAW_MATERIAL_RECEPTION'])
    },
    {
     path: "/raw_material_stock_decreases",
     name: "Bajas de Stock",
     component: RawMaterialStockDecreases,
     hidden: !hasPermission(Permissions['RAW_MATERIAL_STOCK_DECREASE'])
    },
  ]
};

const getControlRecordsFormChildren = ()=> (
  (LocalStorage.get('ControlRecordsForms') || []).map((form)=> ({
      path: `/control_records/${form.id}`,
      name: form.name,
      component: ControlRecords,
    })
))

const registerControlRecordsRoutes = {
  id: "Registros de control",
  header: 'Registros',
  path: "/register",
  displayHeader: hideRawMaterials() && hideSupplies() && hideProduction() && hideProducts(),
  hidden: hideControlRecords(),
  icon: <Clipboard />,
  children: [
    {
     path: "/control_records/productions",
     name: "Producciones",
     component: ControlRecordsProductions,
     hidden: hideControlRecords()
    },
    ...getControlRecordsFormChildren()
  ]
};

const registerSupplyRoutes = {
  id: "Insumos",
  path: "/register",
  header: 'Registros',
  displayHeader: hideRawMaterials(),
  hidden: hideSupplies(),
  icon: <Clipboard />,
  children: [
    {
      path: "/register/supply",
      name: "Ingreso",
      component: Supply,
      hidden: !hasPermission(Permissions['SUPPLY_RECEPTION'], permissionTypes['WRITE'])
    },
    {
      path: "/supply_receptions",
      name: "Histórico",
      component: SupplyReceptions,
      hidden: !hasPermission(Permissions['SUPPLY_RECEPTION'])
    },
    {
      path: "/supply_stock_decreases",
      name: "Bajas de Stock",
      component: SupplyStockDecreases,
      hidden: !hasPermission(Permissions['SUPPLY_STOCK_DECREASE'])
    },
  ]
};

const registerProductionRoutes = {
  id: "Producción",
  path: "/register",
  header: 'Registros',
  displayHeader: hideRawMaterials() && hideSupplies(),
  hidden: hideProduction(),
  icon: <Clipboard />,
  children: [
    {
      path: "/production",
      name: "Nueva",
      component: RegisterProduction,
      hidden: !hasPermission(Permissions['PRODUCTIONS'])
    },
    {
      path: "/started_productions",
      name: "En curso",
      component: Productions,
      hidden: !hasAnyPermission([Permissions['STARTED_PRODUCTIONS'], Permissions['PRODUCTIONS']])
    },
    {
      path: "/identification_productions",
      name: "En identificación",
      component: Productions,
      hidden: !hasAnyPermission([Permissions['IDENTIFICATION_PRODUCTIONS'], Permissions['PRODUCTIONS']])
    },
    {
      path: "/finished_productions",
      name: "Realizadas",
      component: Productions,
      hidden: !hasPermission(Permissions['FINISHED_PRODUCTIONS'])
    },
    {
      path: "/costs",
      name: "Costos",
      component: Costs,
      hidden: (!planFeatures.costs() || !hasPermission(Permissions['FINISHED_PRODUCTIONS']))
    },
    {
      path: "/register/procedure",
      component: Procedure,
      hidden: true
    },
    {
      path: "/register/final_step",
      name: "Paso final",
      component: FinalProductionStep,
      hidden: true
    },
  ]
}

const registerDistributionRoutes = {
  id: "Producto Final",
  path: "/register",
  header: 'Registros',
  displayHeader: hideRawMaterials() && hideSupplies() && hideProduction(),
  hidden: hideProducts(),
  icon: <Clipboard />,
  children: [
    {
      path: "/register/distribution",
      name: "Expedición",
      component: Distribution,
      hidden: !hasPermission(Permissions['PRODUCT_DISTRIBUTION'], permissionTypes['WRITE'])
    },
    {
      path: "/distributions",
      name: "Histórico",
      component: Distributions,
      hidden: !hasPermission(Permissions['PRODUCT_DISTRIBUTION'])
    },
    {
      path: "/product_stock_additions",
      name: "Altas de Stock",
      component: ProductStockAdditions,
      hidden: !hasPermission(Permissions['PRODUCT_STOCK_DECREASE'])
    },
    {
      path: "/product_stock_decreases",
      name: "Bajas de Stock",
      component: ProductStockDecreases,
      hidden: !hasPermission(Permissions['PRODUCT_STOCK_DECREASE'])
    }
  ]
}

const registerStockRoutes = {
  id: "Stock",
  path: "/register",
  header: 'Registros',
  displayHeader: hideRawMaterials() && hideSupplies() && hideProduction() && hideProducts() && hideControlRecords(),
  hidden: hideStock(),
  icon: <Clipboard />,
  children: [
    {
      path: "/raw_material_stock",
      name: "Materia Prima",
      component: RawMaterialBatches,
      hidden:!hasPermission(Permissions['RAW_MATERIAL_STOCK'])
    },
    {
      path: "/supply_stock",
      name: "Insumos",
      component: SupplyBatches,
      hidden: !hasPermission(Permissions['SUPPLY_STOCK']),
    },
    {
      path: "/product_batches_stock",
      name: "Producto Final",
      component: ProductBatches,
      hidden: !hasPermission(Permissions['PRODUCT_STOCK']),
    },
    {
      path: "/reprocesses_stock",
      name: "Reprocesos",
      component: ReprocessesBatches,
      hidden: !hasPermission(Permissions['REPROCESS_STOCK']),
    },
    {
      path: "/reprocess_stock_decreases",
      name: "Bajas de stock de reprocesos",
      component: ReprocessesStockDecreases,
      hidden: true,
    },
  ]
}

const getCustomFormChildren = ()=> (
  (LocalStorage.get('customForms') || []).map((form)=> ({
      path: `/forms/${form.id}`,
      name: form.table_name,
      component: CustomForm,
    })
))

const registerCustomFormRoutes = {
  id: "Planillas Personalizadas",
  path: "/register",
  header: 'Registros',
  displayHeader: hideRawMaterials() && hideSupplies() && hideProduction() && hideProducts(),
  hidden: !hasPermission(Permissions['CUSTOM_FORMS']),
  icon: <Clipboard />,
  children: getCustomFormChildren()
}

const isAdmin = ()=>{
  return LocalStorage.get('isAdmin')
}

const nonconformitiesRoutes = {
  id: "No Conformidades",
  path: "/register",
  header: 'Registros',
  displayHeader: hideRawMaterials() && hideSupplies() && hideProduction() && hideProducts() && !hasPermission(Permissions['CUSTOM_FORMS']),
  hidden: hideNonconformities(),
  icon: <Clipboard />,
  children: [
    {
      path: "/nonconformities",
      name: "Todas",
      component: AllNonconformities,
      hidden: !isAdmin(),
    },
    {
      path: "/received_nonconformities",
      name: "Recibidas",
      component: Nonconformities,
      hidden: hideNonconformities(),
    },
    {
      path: "/sent_nonconformities",
      name: "Enviadas",
      component: Nonconformities,
      hidden: !hasPermission(Permissions['NONCONFORMITY'], permissionTypes['WRITE'])
    },
  ]
}

const traceabilityRoutes = {
  id: "Trazabilidad",
  path: "/traceability",
  header: "Análisis",
  displayHeader: hideKPI(),
  icon: <Sliders />,
  component: Default,
  hidden: !hasPermission(Permissions['TRACEABILITY']),
};

const KPIRoutes = {
  id: "KPI",
  path: "/KPI",
  header: 'Análisis',
  displayHeader: true,
  hidden: hideKPI(),
  icon: <Sliders />,
  children: [
    {
      path: "/KPI/stock",
      name: "Stock",
      component: KPIStock,
      hidden: !hasPermission(Permissions['KPI'])
    },
    {
      path: "/KPI/productions",
      name: "Producciones",
      component: KPIProductions,
      hidden: !hasPermission(Permissions['KPI'])
    }
  ]
}

const landingRoutes = {
  id: "",
  path: "/",
  displayHeader: false,
  component: Landing,
  hidden: true
};

const NotificationsRoutes = {
  id: "Notificaciones",
  path: "/notifications",
  displayHeader: false,
  component: Notifications,
  hidden: true
};

const contactRoutes = {
  id: "Contactanos",
  path: "/contact_us",
  icon: <Mail />,
  component: ContactUs,
};

const MyCloudRoutes = {
  id: "Mi Nube",
  path: "/my_cloud",
  icon: <UploadCloud />,
  component: MyCloud,
  displayHeader:true,
  hidden: !hasPermission(Permissions['CLOUD_STORAGE']),
};

const tutorialRoutes ={
  id: "Video Tutoriales",
  path: "https://trazal.com.ar/manual-del-usuario-trazal-software-de-gestion-control-de-stock-y-trazabilidad-alimentaria/",
  icon: <Video />,
  hidden: false,
  externalURL: true,
  displayHeader: !hasPermission(Permissions['CLOUD_STORAGE'])
}

export const generalPublicRoutes = {
  id: "public_routes",
  path: "/public_routes",
  // icon: <Users />,
  children: [
    {
      id: "Implementation",
      path:"/empresa/:id",
      displayHeader: false,
      component: Implementation,
      hidden: true
    }
  ]
};

export const originalMenu = [
  userRoutes,
  generalRoutes,
  productionRoutes,
  registerRoutes,
  registerSupplyRoutes,
  registerProductionRoutes,
  registerDistributionRoutes,
  registerControlRecordsRoutes,
  registerStockRoutes,
  registerCustomFormRoutes,
  nonconformitiesRoutes,
  KPIRoutes,
  traceabilityRoutes,
  MyCloudRoutes,
  tutorialRoutes,
  landingRoutes,
  contactRoutes,
  NotificationsRoutes
];
export const auth = [authRoutes];
export const publicRoutes = [generalPublicRoutes];
export default originalMenu;

